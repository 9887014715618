<template>
  <div>
    <!-- LOTO -->
    <c-table
      ref="table"
      title="LOTO"
      :columns="gridLoto.columns"
      :gridHeight="gridLoto.height"
      :data="workPermit.lotos"
      :isTitle="true"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&isWriting"
      gridHeightAuto
      selection="multiple"
      rowKey="sopLotoId"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 추가 -->
          <c-btn v-if="editable&&isWriting" label="추가" icon="add" @btnClicked="addFacility" />
          <!-- 제외 -->
          <c-btn v-if="editable&&isWriting" label="제외" icon="remove" @btnClicked="removeFacility" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'swp-loto',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
          lotos: [],
          deleteLotoss: [],
        }
      }
    },
    gridLoto: {
      type: Object,
      default: function() {
        return {
          columns: [
            // {
            //   name: 'equipmentNo',
            //   field: 'equipmentNo',
            //   label: 'LBL0000631', // 설비관리번호
            //   align: 'center',
            //   style: 'width:100px',
            //   sortable: true,
            // },
            {
              name: 'equipmentName',
              field: 'equipmentName',
              label: '설비명', 
              align: 'left',
              style: 'width:150px',
              sortable: true,
              type: 'link',
            },
            // {
            //   name: 'equipmentTypeName',
            //   field: 'equipmentTypeName',
            //   label: '설비유형', 
            //   align: 'center',
            //   style: 'width:150px',
            //   sortable: true,
            // },
            {
              required: true,
              name: 'sopLotoTypeCd',
              field: 'sopLotoTypeCd',
              label: '잠금장치', 
              align: 'center',
              style: 'width:150px',
              sortable: false,
              type: 'select',
              codeGroupCd: 'SOP_LOTO_TYPE_CD'
            },
            {
              name: 'equipmentLocation',
              field: 'equipmentLocation',
              label: '설비위치', 
              align: 'center',
              style: 'width:150px',
              sortable: true,
            },
          ],
          height: '350px'
        };
      },
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          // {
          //   name: 'equipmentNo',
          //   field: 'equipmentNo',
          //   label: '설비관리번호',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            style: 'width:40%',
            sortable: true,
            type: 'link',
          },
          // {
          //   name: 'equipmentTypeName',
          //   field: 'equipmentTypeName',
          //   label: '설비유형',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: true,
          // },
          // 생년월일
          {
            required: true,
            name: 'sopLotoTypeCd',
            field: 'sopLotoTypeCd',
            label: '잠금장치', // 잠금장치
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'select',
            codeGroupCd: 'SOP_LOTO_TYPE_CD'
          },
          {
            name: 'equipmentLocation',
            field: 'equipmentLocation',
            label: '설비위치',
            align: 'center',
            style: 'width:30%',
            sortable: true,
          },
        ],
        data: [],
        height: '350px'
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    height() {
      this.setTabHeight();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.setTabHeight();
    },
    addFacility() {
      this.popupOptions.title = '설비'; // 밸브 및 파열판 설비
      this.popupOptions.isFull = false;
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.workPermit.plantCd, // 사업장코드(관련사업장)
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workPermit.lotos, { equipmentCd: item.equipmentCd }) === -1) {
            this.workPermit.lotos.splice(0, 0, {
              sopLotoId: uid(),  // LOTO 일련번호
              sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
              equipmentCd: item.equipmentCd,
              equipmentNo: item.equipmentNo,
              equipmentName: item.equipmentName,
              equipmentTypeName: item.equipmentTypeName,
              equipmentLocation: item.equipmentLocation,
              sopLotoTypeCd: null,  // 잠금장치
              regUserId: this.$store.getters.user.userId,  // 수정자 ID
              editFlag: 'C',
            })
          }
        })
      }
    },
    removeFacility() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '삭제할 행을 지정하세요.', // 삭제할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.workPermit.deleteLotos) {
            this.workPermit.deleteLotos = [];
          }
          if (item.editFlag !== 'C' && 
            this.$_.findIndex(this.workPermit.deleteLotos, { equipmentCd: item.equipmentCd }) === -1) {
              this.workPermit.deleteLotos.push(item)
          }
          this.workPermit.lotos = this.$_.reject(this.workPermit.lotos, item);
        });
      }
    },
    setTabHeight() {
      let returnText = '500px';
      returnText = this.height && !isNaN(Number(this.height.replace('px', ''))) ? String(Number(this.height.replace('px', '')) - 70) + 'px' : returnText;
      this.gridLoto.height = returnText;
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '설비'; // 밸브 및 파열판 설비
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
